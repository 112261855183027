import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLeading } from 'redux-saga/effects'
import { request } from 'utils/request'

import { authActions } from '.'
import { IActiveToken, IConfirmRecovery, IRecovery, ISignin, ISignup } from './types'

interface signInResponse {
    token: string
}

export function* activeLogin(action: PayloadAction<IActiveToken>) {
    try {
        const response: signInResponse = yield call(request, `auth/active`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(authActions.logined(response.token))
    } catch (error: any) {
        yield put(authActions.statusError())
    }
}

export function* signIn(action: PayloadAction<ISignin>) {
    try {
        const response: signInResponse = yield call(request, `auth`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(authActions.logined(response.token))
    } catch (error: any) {
        yield put(authActions.statusError())
    }
}

export function* signUp(action: PayloadAction<ISignup>) {
    try {
        const response: signInResponse = yield call(request, `auth/signup`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(authActions.signUpFinished(response.token))
    } catch (error: any) {
        yield put(authActions.statusSignUpError())
    }
}

export function* recovery(action: PayloadAction<IRecovery>) {
    try {
        yield call(request, `auth/recovery`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(authActions.recoveryFinished())
    } catch (error: any) {
        yield put(authActions.statusRecoveryError())
    }
}

export function* logout() {
    try {
        yield call(request, `auth/logout`, {
            method: 'POST',
        })

        yield put(authActions.logouted())
    } catch (error: any) {
        yield put(authActions.statusSignUpError())
    }
}

export function* confirmRecovery(action: PayloadAction<IConfirmRecovery>) {
    try {
        const response: signInResponse = yield call(request, `auth/confirm_recovery`, {
            method: 'POST',
            data: action.payload,
        })

        yield put(authActions.confirmRecoveryFinished(response.token))
    } catch (error: any) {
        yield put(authActions.statusConfirmRecoveryError())
    }
}

export function* authWatcher() {
    yield takeLeading(authActions.activeLogin.type, activeLogin)
    yield takeLeading(authActions.signIn.type, signIn)
    yield takeLeading(authActions.signUp.type, signUp)
    yield takeLeading(authActions.recovery.type, recovery)
    yield takeLeading(authActions.confirmRecovery.type, confirmRecovery)
    yield takeLeading(authActions.logout.type, logout)
}
